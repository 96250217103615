// Use this file to add custom JavaScript
//
// A number of JavaScript functions and libraries are included with Quire,
// to see which ones, check the files in themes/quire-starter-theme/source/js // and the list of dependencies in themes/quire-starter-theme/package.json
import { log } from 'loglevel';
import Search from '../../../_plugins/search/search.js'

const globalHandler = {
    $header         : $('header'),
    $footer         : $('footer'),
    $body           : $('body'),
    $html           : $('html'),
    $footnoteRefs   : $('.footnote-ref'),
    $footnotesList  : $('.footnotes-list'),
    $pageButtons    : $('.quire-contents-buttons'),

    initFootnote: function() {
        var self = this;

        // ini dilakukan karena footnote ada bug pada listnya apabila id yang dilempar 2 digit seperti [^18], yang muncul hanya digit pertama (1 bukan 18)
        if (self.$body.find('.footnote-ref').length > 0 && self.$body.find('.footnotes-list').length > 0) {
            var arrFootnote = [];
            //var index = 0;

            self.$body.find('.footnote-ref').each(function() {
                var refId = $(this).find('a').attr('id');
                var refHref = $(this).find('a').attr('href').replaceAll('#', '');

                var tempArr = [];
                tempArr["ref_id"] = refId;
                tempArr["ref_href"] = refHref;

                arrFootnote.push(tempArr);
            });

            self.$body.find('.footnotes-list').find('.footnote-item').each(function() {
                var refData = arrFootnote[$(this).index()];
                
                $(this).attr('id', refData['ref_href']);
                $(this).find('.footnote-backref').attr('href', '#'+refData['ref_id']);
            });
        }
    },

    globalStyle: function() {
        var self = this;
      
        document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
        document.documentElement.style.setProperty('--header-height', self.$header.height()+'px');
        document.documentElement.style.setProperty('--footer-height', self.$footer.height()+'px');
        document.documentElement.style.setProperty('--page-buttons-height', self.$pageButtons.height()+'px');
        document.documentElement.style.setProperty('--search-container-height', $('.search-container').outerHeight() + 'px');
        
        setTimeout(() => {
            document.documentElement.style.setProperty('--scrollbar-width', ((window.innerWidth - document.documentElement.offsetWidth)) + 'px');    
        }, 500);
        
        if (self.$body.find('main').length > 0) {
            var bottomSpace = (self.$footer.hasClass('quire-cover')) ? 0 : self.$pageButtons.height();
            self.$body.find('main').each(function(){
                $(this).css({
                    'margin-top' : self.$header.height(),
                    'margin-bottom' : bottomSpace,
                });
            });
        }
    },

    scrollto: function(top, time) {
        $('html, body').animate({
            scrollTop: top
        }, time);  
    },

    smoothScroll: function(e, url, time = '400'){
        var self = this;
        if (url[0] == '#') {
            var $item = $(url); 
            var headHeight = self.$header.outerHeight() * 1.5;

            if ($item.length > 0) {
                self.scrollto( $item.offset().top - headHeight, time);
            }
        }
    },    

    init: function() {
        var self = this;

        var hash = window.location.hash;
        self.smoothScroll('', hash, 'slow');
        
        self.globalStyle();
        self.initFootnote();

        $(window).on('resize', function() {
            if (self.$html.hasClass('nav-show')) {
                toggleMenu();
            }

            self.globalStyle();
        });

        $(document).on('click', 'a:not(.alt-text-side-image)', function(e) {
            self.smoothScroll(e, $(this).attr('href'));
        });
    }
}

const sidebarHandler = {
    $header              : $('header'),
    $footer              : $('footer'),
    $body                : $('body'),
    $pageButtons         : $('.quire-contents-buttons'),
    $quirePage           : $('.quire-page'),
    $pageSide            : $('.page-with-sidebar'),
    $sidebarContent      : $('#sidebar-content'),
    $sidebarContainer    : $('#sidebar-container'),
    $sidebarButton       : $('#sidebar-button-trigg'),
    $html                : $('html'),
    $sideImage           : $('.side-image'),
    $quirePageCustom     : $('.quire-page-custom'),

    scrollEvent: function()  {
        var self = this;

        if (self.$sidebarContent.find('.ic-container').children().length > 0 || self.$quirePage.hasClass('page-search')) {
            if ($(window).scrollTop() > self.$header.height()) 
                self.$sidebarContainer.removeClass('is-at-header')
            else 
                self.$sidebarContainer.addClass('is-at-header')

                var footPos = self.$body.height() - self.$footer.height();
                if (!self.$footer.hasClass('quire-cover')) footPos = footPos - self.$pageButtons.height();
            if (($(window).scrollTop() + $(window).height()) > (footPos))
                self.$sidebarContainer.addClass('is-at-footer')
            else 
                self.$sidebarContainer.removeClass('is-at-footer')
        } else {
            self.$sidebarContainer.addClass('has-no-content')
        }


        self.$sidebarContent.find('.ic-accordion').each(function() {
            var contentId = $(this).find('.accordion-section__heading .sh-label').attr('id');
            var contentTop = $(this).offset().top;
            var contentBot = contentTop + $(this).outerHeight(true);
            var scrollTop = ($(window).scrollTop() + (self.$header.height() * 1.5));

            if (scrollTop > contentTop && scrollTop < contentBot) {
                self.$sidebarContainer.find('.nav-link').removeClass('is-current');

                var navCurr = self.$sidebarContainer.find('.nav-link[href="#'+contentId+'"]');

                navCurr.addClass('is-current');
                if (navCurr.parent().hasClass('sub-content')) {
                    var navParentId = navCurr.parent().data('parent-id');
                    var navCurrParent = self.$sidebarContainer.find('.nav-link[href="#'+navParentId+'"]');

                    navCurrParent.addClass('is-current');
                }
            }
        });
    },

    makeid: function(length = 6) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    },

    sidebarEvent: function(){
        var self = this;

        $(document).on('click', 'a.alt-text-side-image, a.caption-link', function(e){
            e.preventDefault();
            e.stopPropagation();
            let url     = $(this).attr('href');
            let $target = $(url);

            if ($target.parent().css('display') == 'none') {
                $target = $(this).closest('.ic-container').find('[data-id='+url.substring(1)+']'); 
            }
            let top     = $target.offset().top - (self.$header.height() * 1.5);
            $(window).scrollTop(top);
        })
        
        $(document).on('click', 'html.nav-show a.nav-link', function(){
            toggleMenu();
        })
    },

    renderSideImage: function() {
        var self = this;
        let $newSideImage = self.$html.find('.inner-content .ic-container').find('.side-image');
        if ($newSideImage.length > 0) {
            $newSideImage.each(function(i, e){
                let caption  = '<span class="color-link">Fig. '+(i+1)+'</span>';
                let $caption = $(this).find('.q-figure__caption-content');
                let textHtml = $caption.html()
                let newTextHtml = caption+'. '+textHtml;
                $caption.html(newTextHtml);
            })
            let $sideImageCont = self.$html.find('.side-image-container');
            $newSideImage.clone().appendTo($sideImageCont);
            
            if ($sideImageCont.children().length > 0) {
                $sideImageCont.children().each(function(){
                    $(this).attr('id', $(this).data('id'));
                })
            }
            
            $('.alt-text-side-image').each(function(i, e){
                let caption  = 'Fig. '+(i+1);
                caption = $(this).hasClass('no-parentheses') ? caption : '('+caption+')';
                $(this).text(caption);
            });

            
            $($newSideImage.get().reverse()).each(function(){  
                let $parent = $($(this).parent().get(0));
                let html    = '<p>'+this.outerHTML+'</p>';

                $(html).insertAfter($parent);
                $(this).remove();
            })
           
        }
    },

    renderSidebar: function() {
        var self = this;

        if (self.$sidebarContent !== null && self.$sidebarContainer !== null) {
            var pcCont = self.$sidebarContainer.find('.sc-container.pc-type');
            var rcCont = self.$sidebarContainer.find('.sc-container.rc-type');
            var sideRes = self.$sidebarContainer.find('.side-resources');
            var arrPc = [];
            var arrRc = [];
            var mainTag = '';

            // render side content / page content
            self.$sidebarContent.find('.content').children().each(function() {
                if ($(this).prop("tagName").toLowerCase() == 'h2' || $(this).prop("tagName").toLowerCase() == 'h3') {
                    var pcLabel  = $(this).text();
                    if (!arrPc.includes(pcLabel)) {
                        arrPc.push(pcLabel);
                        var pcItem = document.createElement("div");
                        var randId = self.makeid();
                        pcItem.className = 'sc-item';
                        pcItem.innerHTML = '<a href="#'+randId+'" class="link color-black nav-link">'+pcLabel+'</a>';
                        $(this).attr('id', randId);

                        if ($(this).prop("tagName").toLowerCase() == 'h3') {
                            $(pcItem).addClass('sub-content');
                            pcItem.setAttribute("data-parent-id", mainTag);
                        }
                        if ($(this).prop("tagName").toLowerCase() == 'h2') mainTag =  randId;
            
                        $(pcItem).appendTo(pcCont.find('.sc-item-container'));
            
                        if (!pcCont.hasClass('has-item')) {
                            pcCont.addClass('has-item');
                        }
                    }
                }
            });

            // render related content
            self.$sidebarContent.find('[data-related-content-id]').each(function() {
                var rcId  = $(this).data('related-content-id');
                var rcType  = $(this).data('related-content-type');
                if (!arrRc.includes(rcId)) {
                    arrRc.push(rcId);
                    var rcItem = document.createElement("a");
                    var tempItem = $(this).find('.rc-label');
                    var itemhref = tempItem.find('a').attr('href');
                    var defLabel = '<div class="rc-item color-black link semibold">'+rcId+'</div>';

                    if (itemhref && itemhref != 'javascript:void(0)') {
                        rcItem.href = itemhref;
                        rcItem.target = '_blank';
                    } else {
                        rcItem = document.createElement("div");
                    }

                    rcItem.className = 'sc-item';
                    var iconClass = 'icon-entry';
            
                    if (tempItem.length > 0) {
                        if (rcType == 'artwork') {
                            var itemTitle = tempItem.data('det-title');
                            var itemYear = tempItem.data('det-create-year');
                            var itemCat = tempItem.data('cat-no');
                            defLabel = '<div class="rc-artwork"><div class="art-top color-black link semibold">'+itemTitle+', '+itemYear+'</div><div class="art-bot p3 fix-size color-link semibold">'+rcId+(itemCat ? ', '+itemCat : '')+'</div></div>';
                        } else if (rcType == 'insert' || rcType == 'essay') {
                            if (rcType == 'insert') iconClass = 'icon-encart'
                            else if (rcType == 'essay') iconClass = 'icon-etude'


                            var itemTitle = tempItem.data('rc-title');
                            itemTitle = itemTitle.replaceAll('*', '');
                            defLabel = '<div class="rc-item color-black link semibold">'+itemTitle+'</div>';
                        }

                        rcItem.innerHTML = '<div class="icon '+iconClass+' black-on-small"></div>'+defLabel;
                
                        $(rcItem).appendTo(rcCont.find('.sc-item-container'));
                
                        if (!rcCont.hasClass('has-item')) {
                            rcCont.addClass('has-item');
                        }
                    }
                }
            });

            if (!rcCont.hasClass('has-item') && !pcCont.hasClass('has-item') && !self.$quirePage.hasClass('artwork-entry') && self.$sidebarContainer.find('.side-resources').length == 0) {
                //self.$sidebarContainer.addClass('show-for-desktop');
                //self.$sidebarButton.addClass('show-for-desktop');
            }
            if (pcCont.hasClass('has-item') || rcCont.hasClass('has-item')) sideRes.addClass('side-has-content');

        }
    },

    insertAccordionContent: function(heading, content, container, firstContent = false) {
        var self = this;
        var $accTemplate = self.$sidebarContent.find('.ic-accordion').clone().removeClass('hide');
        var tempContent = $(content).clone();

        //if (firstContent) $($accTemplate).find('.accordion-section__heading').click();
        if ($(heading).prop("tagName").toLowerCase() == 'h3') {
            $($accTemplate).find('.accordion-section').addClass('no-border');   
        }
        $($accTemplate).find('.accordion-section__heading > p').text($(heading).text()).attr('id', $(heading).attr('id')).addClass('sh-label');
        $($accTemplate).find('.ic-content')[0].innerHTML = $(tempContent)[0].innerHTML;
        $($accTemplate).appendTo(container);

        return container;
    },

    renderInnerContent: function() {
        var self = this;

        var firstContent = true;
        var gallery = null;
        var setTitle = null;
        var setContent = document.createElement("div");
        setContent.className = 'set-content';
        setContent = $(setContent);
        var innerContentEl = document.createElement("div");
        innerContentEl.className = 'inner-content';
        innerContentEl = $(innerContentEl);
        var icContainer = document.createElement("div");
        icContainer.className = 'ic-container';
        icContainer = $(icContainer);
        var sideImageContainer = document.createElement("div");
        sideImageContainer.className = 'side-image-container';
        sideImageContainer = $(sideImageContainer);

        self.$sidebarContent.find('.content').contents().not(self.$sidebarContent.find('.content').children()).each(function() {
            $(this).wrap("<p></p>");
        });

        self.$sidebarContent.find('.content').children().each(function() {
            if ($(this).hasClass('artwork-gallery')) {
                gallery = $(this).clone();
            } else {
                if ($(this).prop("tagName").toLowerCase() == 'h2' || $(this).prop("tagName").toLowerCase() == 'h3') {
                    if (setTitle != null) {
                        icContainer = self.insertAccordionContent(setTitle, $(setContent).clone(), icContainer, firstContent);

                        if (firstContent) firstContent = false;
                    }                        

                    $(setContent).empty();
                    setTitle = $(this);
                } else {
                    $(this).appendTo($(setContent));
                }
            }
        });

        if (setTitle != null)
            icContainer = self.insertAccordionContent(setTitle, $(setContent).clone(), icContainer, firstContent);

        self.$sidebarContent.find('.content').empty();

        $(gallery).appendTo(self.$sidebarContent.find('.content'));
        $(icContainer).appendTo(innerContentEl);
        $(sideImageContainer).appendTo(innerContentEl);
        $(innerContentEl).appendTo(self.$sidebarContent.find('.content'));

        self.$sidebarContent.find('p').each(function() {
            if ($(this).text().trim() == '') $(this).remove();
        });
    },

    customPageRender: function(){
        var self = this;
        if (self.$quirePageCustom.length > 0) {
            var $rightContent = self.$quirePageCustom.find('.right-content');
            var $figure = $rightContent.find('.side-image');
            //var $hero = self.$quirePageCustom.find('.quire-page__header.hero');
            var $leftContent = self.$quirePageCustom.find('.left-content .container .content');

            if ($figure.length > 0) {
                let $clonedFigure = $figure.clone();
                $figure.addClass('show-for-desktop')
                $clonedFigure.addClass('hide-for-desktop');
                //$clonedFigure.insertAfter($hero);
                $clonedFigure.insertBefore($leftContent);
            }
        }
    },

    init: function () {
        var self = this;
        self.customPageRender();

        if (self.$pageSide.length > 0) {
            self.renderSidebar();
            self.renderInnerContent();
            self.renderSideImage();
            self.scrollEvent();
            self.sidebarEvent();

            self.$sidebarButton.on('click', function() {
                $(this).toggleClass('is-active');
                self.$sidebarContainer.toggleClass('is-show');
                self.$html.toggleClass('disable-scroll');
                self.$html.toggleClass('nav-show');
                self.$header.toggleClass('rc-show');
            });

            $(window).on('scroll', function() {
                self.scrollEvent();
            });
        }
    }
}

const tocHandler = {
    $detailMenu: $('.detail-menu'),
    $contentContainer: $('.detail-menu .content-container'),
    $masonryGrid: $('.masonry-grid'),
    $quireSecondary: $('.quire__secondary'),

    events: function(){
        var self = this;
        
        $(document).on('click', function(e){
            if (self.$quireSecondary.hasClass('is-expanded') && ($(e.target).closest('.quire__secondary').length == 0) && ($(e.target).parents('.head-icon.nav-icon').length == 0)) {
                toggleMenu();
            }
        });

        /* 
        $(document).on('click', '[href*="#open-detail-menu"]', function(e){
            e.preventDefault();
            let $item = $(this).siblings('ol');
            
            let $secItem = $(this).parent('.section-item');
            $secItem.siblings().removeClass('active');
            $secItem.siblings().children('a.active').removeClass('active');
            $secItem.toggleClass('active');
    
            self.$contentContainer.html('');
            if ($secItem.hasClass('active')) {
                self.$contentContainer.html($item.html());
            }
            self.renderTitle();
        });
    
        $(document).on('click', '[href*="#open-child"]', function(e){
            e.preventDefault();
            $(this).parent().toggleClass('active');
        }); 

        $(document).on('click', '.head-icon.nav-icon .icon-bars', function(e){
            let $itemActive = self.$quireSecondary.find('a[href="#open-child"].active');
            let openParentOnly = false;
            if ($itemActive) {
                $itemActive = self.$quireSecondary.find('a.active');
                
                if ($itemActive) {
                    $itemActive = self.openParent($itemActive, '#open-child', true);
                    if ($itemActive) {
                        $itemActive.click();
                    } else {
                        openParentOnly = true
                        $itemActive = self.$quireSecondary.find('a.icon.active');
                    }
                }
            }

            if ($itemActive) {
                if (!openParentOnly) $itemActive.click();
                self.openParent($itemActive, '#open-detail-menu');
            }
        });*/
    },

    openParent: function($itemActive, href, forceClick = false){
        let $parentItem = $itemActive.parents('ol').siblings('a:not(.button-child)[href="'+href+'"]');
        if ($parentItem.length > 0) {
            if ((!$($parentItem.get(0)).hasClass('active')) || forceClick ) {
                $($parentItem.get(0)).click();
            }
            $($parentItem.get(0)).addClass('active');
            return $($parentItem.get(0));
        } 
        return null;
    },

    renderTitle: function(){
        let itemLength = this.$contentContainer.find('.artwork-id').length;
        if (itemLength > 0) {
            this.$detailMenu.addClass('has-title');
        } else {
            this.$detailMenu.removeClass('has-title');
        }
    },

    initMasonry: function(){
        if ( (this.$masonryGrid.length > 0) && (this.$masonryGrid.find('.grid-item').length > 0)) {
            var $grid = this.$masonryGrid.masonry({
                itemSelector: '.grid-item',
                percentPosition: true,
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer'
            });

            $grid.imagesLoaded().progress( function() {
                $grid.masonry('layout');
            });
        }
    },

    init: function(){
        this.events();
        this.initMasonry();
    }
}

const searchHandler = {
    $searchInput: $('#js-search-input'),
    $quireSearch: $('#js-search'), 
    $resultsContainer: $('#js-search-results-list'),
    $resultsTemplate: $('#js-search-results-template'),
    $filterTemplate: $('#js-filter-template'),
    $searchCount: $('.js-search-count'),
    $filterContainer: $('#js-filter-container'),
    $searchBtn: $('.js-search-button'),
    $captionRefine: $('.js-caption-refine'),
    $closeSidePanel: $('#js-close-side-panel'),
    $clearSearch: $('#js-clear-search'),
    $btnSearch: $('#js-btn-search'),
    $pageContainer: $('.page-container'),
    $applyFilter: $('#js-apply-filter'),
    QUIRE_SEARCH: null,
    
    events: function(){
        var self = this;
        var base_url = window.location.origin;
        var pathname = '/search/';
        var searchIndex = new URL('search-index.json', base_url);
        this.$quireSearch.data('search-index', searchIndex.href);

        this.$searchInput.on('keypress', function(e){
            let query = $(this).val().trim();
            if (e.which == 13) {
                let url   = base_url+pathname+'?query='+query;
                window.location.href = url
            }
            if (query) {
                $(this).addClass('filled');
            } else {
                $(this).removeClass('filled');
            }
        });

        this.$clearSearch.on('click', function(e){
            self.$searchInput.val('');
            self.$searchInput.removeClass('filled');
            self.$searchInput.focus();
            let url   = base_url+pathname;
            window.location.href = url
        })

        this.$btnSearch.on('click', function(e){
            var e = $.Event('keypress');
            e.which = 13; 
            self.$searchInput.trigger(e);
        })
        
        this.$closeSidePanel.on('click', function(e) {
            e.preventDefault();
            sidebarHandler.$sidebarButton.removeClass('is-active');
            sidebarHandler.$sidebarContainer.removeClass('is-show');
            sidebarHandler.$html.removeClass('disable-scroll').removeClass('nav-show');
            sidebarHandler.$header.removeClass('rc-show');
        });

        this.$applyFilter.on('click', function(e){
            e.preventDefault();
            let query = self.$searchInput.val().trim();
            let param = base_url+pathname+'?query='+query;
            self.$filterContainer.find('input[type=radio]').each(function(){
                if ($(this).prop('checked') === true) {
                    param += '&'+$(this).attr('name')+'='+$(this).val();
                }
            })
            window.location.href = param;
        })

        $(document).on('click', '.small-checkbox', function(e){
            if ($(this).prev().prop('checked') === true) {
                e.preventDefault();
                $(this).prev().prop('checked', false);
                $(this).removeClass('checked');
            }
        }); 
    },

    searchData: function(arrQuery){
        let searchInstance = this.QUIRE_SEARCH;
        let textQuery      = arrQuery['query'];
        if (!textQuery) {
            this.$searchInput.removeClass('filled');
            this.$captionRefine.parent().addClass('hide');
            return;
        } else {
            this.$captionRefine.removeClass('hide');
            this.$searchInput.addClass('filled');
        }

        let searchResults  = searchInstance.search(textQuery);
        let filteredResults = [];
        $.each(searchResults, function(sKey, item){
            let maxItemMatched = Object.keys(arrQuery).length - 1;
            let ItemMatched = 0;

            $.each(item, function(itemKey, itemValue){
                $.each(Object.keys(arrQuery), function(key, val) {
                    if (val !== 'query') {   
                        if (itemKey == val) {
                            if (arrQuery[val] == itemValue) {
                                ItemMatched++;     
                            }
                        }
                    } 
                });

            })

            if (ItemMatched == maxItemMatched) {
                filteredResults.push(item);
            }
        });

        this.displayResults(filteredResults, textQuery)
    },

    loadSearchData: function() {
        let self = this;
        let dataURL = this.$quireSearch.data('search-index')
        if (!dataURL) {
          console.warn('Search data url is undefined')
          return
        }

        fetch(dataURL).then(async (response) => {
            const { ok, statusText, url } = response
            if (!ok) {
                console.warn(`Search data ${statusText.toLowerCase()} at ${url}`)
                return
            }
            const data = await response.json()
            self.QUIRE_SEARCH = new Search(data)
        })
    },

    clearResults: function() {
        this.$resultsContainer.innerText = '';
        this.$searchCount.html('0');
    },

    setName: function(text){
        return text.toString().replaceAll(' ', '_').toLowerCase();
    },

    capitalizeFirstLetter: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    displayResults: function(results, textQuery){
        let self = this;
        let arTot = {};
        let arMat = {}; 
        let arDate = {};
        let results_length = 0;
        this.clearResults();
        
        if (results.length > 0) {
            results.forEach(result => {
                // data
                let $clone = $(self.$resultsTemplate.children().clone());
                $clone.removeClass('hide');
                
                let $item  = $clone.find('.js-search-results-item')
                let $image = $clone.find('.js-search-results-image')
                let $title = $clone.find('.js-search-results-item-title')
                let $type  = $clone.find('.js-search-results-item-type')
                let $content = $clone.find('.js-search-results-item-content')
                if (result) {
                    let tmpQuery = textQuery.toLowerCase();
                
                    if ((!result.title.toLowerCase().includes(tmpQuery)) && (!result.content.toLowerCase().includes(tmpQuery))) {
                        return;
                    }

                    // filter             
                    if (result.type_of_text !== "") { 
                        arTot[self.setName(result.type_of_text)] = result.type_of_text;
                    }

                    if (result.materials !== "") { 
                        arMat[self.setName(result.materials)] = result.materials;
                    }
                    
                    if (result.date !== "") {
                        arDate[self.setName(result.date)] = result.date.toString();
                    }

                    $item.attr('href', result.url);
                    $title.html( self.processText(result.title, textQuery) );
                    $type.html( result.type_of_text );
                    $content.html( self.processText(result.content, textQuery, true) );
                    if (result.artwork) {
                        var urlAddon = $globalThumbnailRes ? $globalThumbnailRes+'/' : '';
                        var mediaUrl = result.artwork.media_url ? result.artwork.media_url.replaceAll(result.artwork.media_base_url, result.artwork.media_base_url+urlAddon) : '';
                        //$image.attr('src', result.artwork.media_url);
                        $image.attr('src', mediaUrl);
                    } else {
                        $image.addClass('hide')
                    }
                    $clone.appendTo(self.$resultsContainer);
                    results_length++;
                }
            })

            self.renderFilter(arTot, 'Type of Text', 'Type de contenu');
            self.renderFilter(arMat, 'Materials');
            self.renderFilter(arDate, 'Date');
        } else {
            let $clone = $(self.$resultsTemplate.children().clone());
            $clone.removeClass('hide');
            let $title = $clone.find('.js-search-results-item-title')
            $title.html( $title.data('empty-message') );
            $clone.appendTo(self.$resultsContainer);
        }

        self.$pageContainer.addClass('searched');
        self.$searchCount.html(results_length);
    },

    processText: function(text, textQuery, isContent = false){
        text = this.HTMLescape(text);
        let arrText  = text.trim().split(' ');
        let html     = '';
        let arrQuery = textQuery.trim().split(' ');
        let queryCount = arrQuery.length;
        arrQuery.unshift(textQuery);

        if (queryCount > 1) {
            let tmpText    = text.toLowerCase();
            
            if ( tmpText.includes(textQuery.toLowerCase()) ) {
                let arrTmpText = tmpText.trim().split(textQuery.toLowerCase());
                
                if (arrTmpText) {
                    let resultText = '';
                    $.each(arrTmpText, function(key, item){
                        let arrItem = item.trim().split(' ');
                        if (arrItem) {
                            let startI = arrItem.length > 20 ? arrItem.length - 20 : 0;  
                            let endI = arrItem.length-1;
                            
                            if (key == 0) {
                                resultText += arrItem.slice(startI, endI).join(' ')+' <strong>'+textQuery+'</strong> ';
                            } 

                            if (key == arrTmpText.length-1) {
                                startI = 0;
                                endI = arrItem.length > 20 ? 20 : arrItem.length;
                                resultText += arrItem.slice(startI, endI).join(' ');
                            }
                        }
                    });
                    html += isContent ? '<p>...'+resultText+'...</p>' : resultText;
                }
            }
        }

        if (!html) {
            $.each(arrText, function(key, item){
                let tmp = item.toLowerCase();
                let line = '';
                $.each(arrQuery, function(keyQ, itemQ) {
                    if (tmp.includes(itemQ.toLowerCase())) {
                        let startI = key < (20) ? 0 : (key - 20); 
                        let endI   = key > (arrText.length - 20) ? arrText.length : (key + 20); 
                        let line = '';
                        for (let i = startI; i < endI; i++) {
                            if (arrText[i]) {
                                line    += ' '+ ( i == key ? '<strong>'+arrText[i]+'</strong>' : arrText[i] );
                                arrText[i] = '';
                            }
                        }
                        if (line) {
                            line = isContent ? '<p>...'+line.trim()+'...</p>' : line.trim();
                            html += line;
                        }   
                    }
                })
            });
        }

        html = (html) ? html : text;
        html = (isContent) ? html : html.replaceAll("*", "");
        return html;
    },

    renderFilter: function(data, title, caption = ''){
        var self      = this;
        var baseUrl   = window.location.origin + window.location.pathname; 
        var arrSearch = this.getArrSearchQuery();
        caption = caption == '' ? title : caption;

        if (Object.keys(data).length > 0) {
            let $clone = $(self.$filterTemplate.children().clone());
            let $title = $clone.find('.js-filter-title');
            let $item  = $clone.find('.js-filter-item');
            $title.html(caption);
            
            $.each(data, function(key, item){
                let newUrl     = '';
                let queryMatch = false;
                let strClass   = '';  
                let strVal     = item.toString();

                $.each(Object.keys(arrSearch), function(nkey, val) {
                    if (val == self.setName(title)) {
                        queryMatch = true;
                        if (arrSearch[val] == strVal) {
                            strClass = 'checked';
                        } else {
                            newUrl += '&'+ val + '=' + strVal;
                        }
                    } else {
                        newUrl += '&'+ val + '=' + arrSearch[val];
                    }
                })

                newUrl += (!queryMatch) ? '&'+ self.setName(title) + '=' + strVal : '';
                newUrl  = baseUrl + '?' + newUrl.slice(1);
                
                let $htmlItem = $('<div class="show-for-desktop"><a class="checkbox link semibold color-black '+strClass+'" href="'+newUrl+'">'+item+'</a></div>')
                $htmlItem.appendTo($item);
                let id = self.setName(title)+'_'+key;
                $htmlItem = $('<div class="hide-for-desktop"> <input type="radio" name="'+self.setName(title)+'" class="checkbox-style " value="'+item+'" id="'+id+'" '+strClass+'><label class="checkbox link semibold color-black small-checkbox '+strClass+'" for="'+id+'">'+item+'</label></div>')
                $htmlItem.appendTo($item);
            })
            $clone.appendTo(self.$filterContainer);
        }
    },

    HTMLescape: function(value){
        value = value.replaceAll('>', '> ');
        return $('<div/>').html(value).text();
    },

    getArrSearchQuery: function(){
        let search = decodeURI(window.location.search.replace('?','')).replaceAll('+', ' ').split('&');
        let arrSearch = [];
        $.each(search,function (key, val) {
            var v = val.split('=');
            arrSearch[v[0]] = v[1];
        }); 
        return arrSearch
    },
    
    init: function(){
        var self = this;
        this.events();
        this.loadSearchData();

        if (window.location.pathname == '/search/') {
            let arrSearch = self.getArrSearchQuery();
            sidebarHandler.$header.find('.search-icon').addClass('disabled');
            sidebarHandler.$header.find('.icon-search').addClass('gray');

            if (arrSearch) {
                if (arrSearch['query']) {
                    self.$searchInput.val(arrSearch['query']);
                }

                setTimeout(() => {
                    self.searchData(arrSearch);                         
                }, 500);
            }
        }
    }
}


jQuery(document).ready(function( $ ) {
    sidebarHandler.init();
    globalHandler.init();
    tocHandler.init();
    searchHandler.init();

});